<!-- tsx -->
<template>
  <div class="financialRecord">
    <section class="toHeader">
      <div class="left">
        <span style="cursor: pointer" @click="goBack()">
          <i class="el-icon-arrow-left" style="color:#2483ff"></i>财务记录
        </span> 
      </div>
      <div class="right">
        <span v-for="(item,index) in dateArr" :key="index" :class="{'activeColor': item.active}" @click="showRep(item.id)">{{item.title}}</span>
        <el-date-picker
          v-model="selectData"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyyMMdd"
        >
        </el-date-picker>
      </div>
    </section>

    <section class="tables">
      <div class="tableHeader">
        <span style="cursor: pointer">{{typeRep}}</span>
      </div>
      <el-table :data="tableData" style="width: 100%; min-height: 400px;">
        <template slot="empty">
          <div class="no-datas1">
            <div class="no-data-img" style="margin: 100px auto 0px; width: 120px; height: 100px;">
              <img src="../../../assets/images/no_data.png" alt="" style="width: 100%;">
            </div>
            <div class="no-data" style="margin-top: -25px !important; text-align: center;">{{ $t("message.No_data") }}</div>
          </div>
        </template>
        <el-table-column prop="occurTime" label="时间" width="180">
          <template slot-scope="scope">{{ scope.row.occurTime|dateFormate }}</template>
        </el-table-column>
        <el-table-column prop="bizName" label="类型" width="180"></el-table-column>
        <el-table-column prop="amount" label="金额" width="180"></el-table-column>
        <el-table-column prop="marketName" label="市场" width="180"></el-table-column>
        <el-table-column prop="note" label="流水号"></el-table-column>
      </el-table>

      <el-pagination
        :current-page="pageData.currentPage"
        :page-sizes="pageData.pageSizes"
        :page-size="pageData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>

    </section>

  </div>
</template>

<script >
export default {
components: {},
  data() {
    return {
      activeName:'first',
      selectNav: '0',
      tableData: [],
      pageData: {
        total: 10,
        pageSizes: [5, 10, 20],
        pageSize: 10,
        currentPage: 1
      },
      selectData: '',
      dateArr:[
        { id:0, title:'入金记录', active: true},
        { id:1, title:'出金记录', active: false},
        { id:2, title:'划转记录', active: false}
      ],
      typeRep:'入金记录'

    }
  },
created() {},
mounted() {
  this.param_queryMyIntegralDetail()
},
computed: {},
watch: {
  selectData(val, oldVal) {
     this.pageData = {
       total: 10,
       pageSizes: [5, 10, 20],
       pageSize: 10,
       currentPage: 1
     }
     this.tableData = []
     this.param_queryMyIntegralDetail()
  },
  selectNav(val, oldVal) {
    this.pageData = {
      total: 10,
      pageSizes: [5, 10, 20],
      pageSize: 10,
      currentPage: 1
    }
    this.tableData = []
    this.param_queryMyIntegralDetail()
  }
},
methods: {
  handleSizeChange(val) {
    this.pageData.pageSize = val
    this.param_queryMyIntegralDetail()
  },
  handleCurrentChange(val) {
    this.pageData.currentPage = val
    this.param_queryMyIntegralDetail()
  },
  showRep(ind){
    this.dateArr.forEach(element => {
      element.active = false
    })
    this.dateArr.forEach(element => {
      if(ind == element.id){
        element.active = true
      }
    })
    this.selectNav = ind
    if(ind == 0){
      this.typeRep = '入金记录'
    }else if(ind ==1){
      this.typeRep = '出金记录'
    }else{
      this.typeRep = '划转记录'
    }
  },
  goBack(){
    this.$router.push('/conditionOfAssets')
  },
  param_queryMyIntegralDetail() {
      // // console.log(this.selectData)
      let protocolUrlData = {}
      if (this.selectData) {
        // 历史
        protocolUrlData = protocolFwd.param_queryMyIntegralDetailHis
        protocolUrlData.param.starTime = this.selectData[0]
        protocolUrlData.param.endTime = this.selectData[1]
      } else {
        // 当前
        protocolUrlData = protocolFwd.param_queryMyIntegralDetail
      }
      if (this.selectNav == '0') {
        // 入金
        protocolUrlData.param.typeCode = 101
      } else if (this.selectNav == '1') {
        // 出金
        protocolUrlData.param.typeCode = 102
      } else {
        // 其它
        protocolUrlData.param.typeCode = null
      }
      protocolUrlData.param.page = this.pageData.currentPage - 1
      protocolUrlData.param.size = this.pageData.pageSize
      http.postFront(protocolUrlData).then(response => {
        if (response.data) {
          if (response.data && response.data.code == '0') {
            this.pageData.total = response.data.value.totalElements
            const arr = response.data.value.content
            this.tableData = arr
          }
        }
      })
  }

},
}
</script>

<style lang='scss' scoped>
// @import url(); 引入公共css类
.financialRecord{
  width: 100%;
  height: 80px;
  // display: flex;
  margin-bottom: 24px;
  background-color: #1b2945;
  // justify-content: center;
  // align-items: flex-end;
  // overflow: hidden;
}
.toHeader{
  height: 80px;
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  .left{
    float: left;
    font-size: 16px;
    color: #fff;
    margin-right: 100px;
    span{
      line-height: 80px;
    }
  }
  .right{
    float: left;
    width: 60%;
    // margin-left: 30px;
    span{
      color: #87a2cd;
      margin-right: 40px;
      line-height: 80px;
      display: inline-block;
      border-bottom: 3px solid transparent;
    }
  }

}
.tables{
  width: 1200px;
  border: 1px solid #e5ebf5;
  margin: 30px auto;
  .tableHeader{
    height: 48px;
    line-height: 48px;
    color: #4b5666;
    padding: 0 24px;
    background-color: #f2f6fa;
    border-bottom: 1px solid #e6ecf2;
  }
}
.el-tabs__item {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.el-pagination{
  text-align: center;
  margin: 15px 0;
}
/deep/.el-tabs__nav-wrap::after{
  background-color: none!important;
}
.el-tabs__header{
  line-height: 80px!important;
  margin: 0!important;
}
.activeColor{
  color: #fff!important;
}
</style>
